import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import "./PageProgress.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query ProgressBar {
        agilityProgressBar(
          properties: { referenceName: { eq: "pageprogressbar" } }
        ) {
          barColor {
            customFields {
              hexadecimalValue
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <PageProgress
          color={data.agilityProgressBar.barColor.customFields.hexadecimalValue}
        />
      )
    }}
  />
)

/**
 * Component to set the page progress using the color passed by the props.
 * @function PageProgress
 */
const PageProgress = ({ color }) => {
  const [progress, setProgress] = useState(0)

  /**
   * Handles the scroll and changes the internal state progress.
   * @function handleScroll
   */

  const handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight
    const documentHeight = document.documentElement.scrollHeight

    const scrollPos =
      typeof window !== `undefined` ? window.scrollY || window.pageYOffset : 0
    const scrollHeight =
      typeof window !== `undefined` ? documentHeight - windowHeight : 0

    setProgress((scrollPos * 100) / scrollHeight)
  }

  /**
   * useEffect() hook to and and remove the handleScroll handler.
   */

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  })

  /**
   * Renders the page progress element and displays the width depending on the progress state value.
   */

  return (
    <div
      className="page-progress"
      style={{
        width: `${progress}%`,
        backgroundColor: color,
      }}
    >
      <span className="sr-only">
        Page Scroll Progress: {`${progress.toFixed(2)}%`}
      </span>
    </div>
  )
}
